/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classNames from 'classnames';
import { SimpleTooltip } from '@design-system';
import Color from 'color';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

/**
 * Buttons are used to initialize an action. Button labels express what action will occur when the user interacts with it.
 */

const Certificate = ({
  color,
  active = false,
  title,
  src,
  alt,
  unlimited = false,
  content,
  onClick,
  totalSigned = 0,
  totalCount = 0,
  totalVobo = 0,
  maxVobo = 300,

}) => (
  <div className="flex-row">
    <div className="cursor-pointer" onClick={onClick}>
      <div
        className={classNames(
          'm-auto mb-4 flex h-[100px] w-[97px] flex-col justify-center rounded-2xl border-2 bg-basic-white p-5',
          active ? 'border-primary-300' : 'border-gray-200',
        )}
        style={
          active
            ? {
              background: Color(color).alpha(0.05).hsl().toString(),
              borderColor: color,
            }
            : {}
        }>
        <img
          src={src}
          alt={alt}
          className="mx-auto self-center justify-self-center"
        />
      </div>
      <div className="text-center">
        <p>{title}</p>
        {unlimited ? (
          <span>
            ({totalVobo}/{maxVobo})
          </span>
        ) : (
          <span style={{ color }}>{`(${totalSigned}/${totalCount})`}</span>
        )}
      </div>

      <SimpleTooltip label={content} placement="bottom" />
    </div>
  </div>
);

export const CertificateType: React.FC<any> = ({
  color,
  certificate = 'wcertificate',
  onClick,
  totalSigned = 0,
  totalCount = 0,
  totalVobo = 0,
  maxVobo = 300,
  showBasicCertificates,
}) => {
  const { t } = useTranslation();
  const handleClick = (certificate) => {
    if (onClick) onClick(certificate);
  };

  return (
    <div>
      <div className="mb-4 text-left text-paragraph font-bold text-gray-500">
        {t(translations.CERTIFICATE_TYPES.CHOOSE_CERTIFICATE)}
      </div>
      <div className="flex justify-evenly">
        {showBasicCertificates && (
          <Certificate
            color={color}
            active={certificate === 'wcertificate' && showBasicCertificates}
            title={t(translations.SIDEBAR.SUBS_CERT_BASIC)}
            src="/img/doc-sin-certificar.svg"
            alt="doc-certificado"
            unlimited
            totalVobo={totalVobo}
            maxVobo={maxVobo}
            content={
              <>
                {t(translations.CERTIFICATE_TYPES.INCLUDES)}
                <ol className="list-disc py-2 px-5 text-paragraph-sm">
                  <li>{t(translations.CERTIFICATE_TYPES.BASIC_TEXT)}</li>
                </ol>
              </>
            }
            onClick={() => handleClick('wcertificate')}
          />
        )}

        <Certificate
          color={color}
          active={certificate === 'certificate' || !showBasicCertificates}
          title={t(translations.SIDEBAR.SUBS_CERT_SMART)}
          src="/img/doc-certificado.svg"
          alt="doc-certificado"
          totalSigned={totalSigned}
          totalVobo={totalVobo}
          maxVobo={maxVobo}
          totalCount={totalCount}
          content={
            <>
              {t(translations.CERTIFICATE_TYPES.INCLUDES)}
              <ol className="list-disc py-2 px-5 text-paragraph-sm">
                <li>{t(translations.CERTIFICATE_TYPES.BASIC_TEXT)}</li>
                <li>{t(translations.CERTIFICATE_TYPES.SMART_TEXT_NOM)}</li>
                <li>{t(translations.CERTIFICATE_TYPES.SMART_TEXT_ADOBE)}</li>
                <li>
                  {t(translations.CERTIFICATE_TYPES.SMART_TEXT_VERIFICATION)}
                </li>
                <li>{t(translations.CERTIFICATE_TYPES.SMART_TEXT_BITCOIN)} </li>
              </ol>
            </>
          }
          onClick={() => handleClick('certificate')}
        />
      </div>
    </div>
  );
};
