import * as Yup from 'yup';
import { translations } from 'translations';

export interface IRecoveryPasswordFormFields {
  /**
   * Confirm password field, the value of this field should match with the
   * password entered on the form.
   */
  confirmPassword: string;
  /**
   * User email.
   */
  email: string;
  /**
   * User's new password.
   */
  new_password: string;
}

export const RecoveryPasswordFormValidationSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required(translations.ERRORS.CONFIRM_PASSWORD)
    .oneOf([Yup.ref('new_password')], translations.ERRORS.PASSWORDS_DONT_MATCH),
  email: Yup.string()
    .email(translations.ERRORS.VALID_EMAIL)
    .required(translations.ERRORS.REQUIRED_EMAIL)
    .min(6, translations.ERRORS.VALID_EMAIL_CHARACTERS),
  new_password: Yup.string()
    .required(translations.ERRORS.REQUIRED_PASSWORD)
    .min(6, translations.ERRORS.PASSWORD_LENGTH),
});

export const DEFAULT_VALUES = {
  confirmPassword: '',
  email: '',
  new_password: '',
};
