import React from 'react';
import { Td, ITdProps } from '../components/Td';
import cn from 'classnames';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import fp from 'lodash/fp';
import { DotsIcon } from '@design-system';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface ITdActionsProps extends ITdProps {
  /**
   * Branding color, customizable only for Enterprise and Business users.
   */
  brandColor: string;
  /**
   * If `true`, the logged user is owner of the document so the delete
   * capability will be available.
   */
  isOwner?: boolean;
  /**
   * On change folder action.
   */
  onChangeFolder?: () => void;
  /**
   * On delete action.
   */
  onDelete?: () => void;
  /**
   * On download action.
   */
  onDownload?: () => void;
  /**
   * On update action.
   */
  onUpdate?: () => void;
}

export const TdActions: React.FC<ITdActionsProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const {
    brandColor,
    children,
    className,
    isOwner = false,
    onChangeFolder,
    onDelete,
    onDownload,
    onUpdate,
    ...rest
  } = props;

  return (
    <Td className={cn(className)} {...rest}>
      <Menu>
        <MenuButton>
          <DotsIcon color={brandColor} height={20} width={4} />
        </MenuButton>
        <MenuList bg="#E6E6E6">
          {!fp.isNil(onDownload) ? (
            <MenuItem onClick={onDownload}>
              {t(translations.COMMON.DOWNLOAD)}
            </MenuItem>
          ) : null}
          {!fp.isNil(onChangeFolder) && isOwner ? (
            <MenuItem onClick={onChangeFolder}>
              {t(translations.FOLDERS_PAGES.CHANGE_FOLDER)}
            </MenuItem>
          ) : null}
          {!fp.isNil(onUpdate) && isOwner ? (
            <MenuItem onClick={onUpdate}>
              {t(translations.COMMON.EDIT)}
            </MenuItem>
          ) : null}
          {!fp.isNil(onDelete) && isOwner ? (
            <MenuItem onClick={onDelete}>
              {t(translations.COMMON.DELETE)}
            </MenuItem>
          ) : null}
        </MenuList>
      </Menu>
    </Td>
  );
};
