import React, { useState, useEffect } from 'react';
import { Button } from '@design-system';
import { Logo, LogoType, Avatar, Progressbar } from '@design-system';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useRouter } from 'hooks/useRouter';
import classNames from 'classnames';
import { useSession } from 'store/session';
import { Link } from 'react-router-dom';
import { convertSize } from 'utils/convertSize';
import { SidebarItem } from './components/SidebarItem';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { useFeature } from '@growthbook/growthbook-react';
import { MARKETING_FEATURE, REWARDS_FEATURE, FORMS_FEATURE } from 'constants/features';

const subscriptions = [
  {
    id: 'docs',
    title: translations.SIDEBAR.SUBS_CERT_SMART,
  },
  {
    id: 'docsBasic',
    title: translations.SIDEBAR.SUBS_CERT_BASIC,
  },
  {
    id: 'proof',
    title: translations.SIDEBAR.SUBS_PROOF,
  },
];

const getCount = (id, accountLimits) => {
  if (id === 'users') {
    return accountLimits.count_users;
  }
  if (id === 'docs') {
    return accountLimits.count_signed_documents;
  }
  if (id === 'docsBasic') {
    return (accountLimits as any)?.count_documents_vobo_nom151;
  }
  if (id === 'storage') {
    if (accountLimits.count_storage > 0) {
      return convertSize(accountLimits.count_storage);
    } else {
      return 0;
    }
  }
  if (id === 'proof') {
    let kycCount;
    const current = (accountLimits as any).count_kyc_validations;
    const maxKyc = (accountLimits as any).max_kyc_validations;
    const countKyc = (accountLimits as any).count_kyc_validations;
    if (countKyc > maxKyc) {
      kycCount = countKyc;
    } else kycCount = (countKyc - maxKyc) * -1;
    return current;
  }
};
const getLimit = (id, accountLimits) => {
  if (id === 'docs') {
    return accountLimits.max_signed_documents;
  }
  if (id === 'docsBasic') {
    return (accountLimits as any)?.max_documents_vobo_nom151;
  }
  if (id === 'proof') {
    const maxKyc = (accountLimits as any).max_kyc_validations;
    return maxKyc;
  }
};

const getProgress = (count, limit) => {
  return (count * 100) / limit;
};

const getPlan = (branding: boolean) => {
  if (branding) return 'Business';
  return 'Start';
};

const AccountSubscription = ({
  t,
  color,
  accountLimits,
  organizations,
  showBasicCertificates,
}) => {
  return (
    <div className="flex flex-col">
      <p className="mt-4 text-paragraph-sm text-white">
        {t(translations.SIDEBAR.SUBS_PLAN)}:{' '}
        <strong>
          {getPlan(accountLimits?.custom_branding_availability)}
        </strong>
      </p>
      {subscriptions.map((sub) => {

        if (sub.id === 'docsBasic' && !showBasicCertificates) {
          return null;
        }
        const progress =
          sub.id === 'orgs'
            ? 100
            : getProgress(
              getCount(sub.id, accountLimits),
              getLimit(sub.id, accountLimits),
            );
        return (
          <div key={sub.id} className="mb-3 mt-0">
            <div className="mb-1 text-white">
              <p className="text-paragraph-sm">{t(sub.title)}</p>
              {sub.id !== 'orgs' ? (
                <>
                  <p className="text-caption">
                    {getCount(sub.id, accountLimits)}/
                    {getLimit(sub.id, accountLimits)}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-caption">{organizations}</p>
                </>
              )}
            </div>
            <Progressbar color={color} progress={progress} />
          </div>
        );
      })}
    </div>
  );
};
export const Sidebar = ({
  primaryColor,
  logo,
  handleLogout,
  isOpen,
  setIsOpen,
  isRewardsAvailable,
}) => {
  const { t } = useTranslation();
  const [userAvatar, setUserAvatar] = useState();
  const {
    isLoggedIn,
    user,
    currentOrganization,
    accountLimits,
    organizations,
    statsOpened,
    setStatsOpened,
    organizationImages,
    showBasicCertificates,
  } = useSession();
  const router = useRouter();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const isFormFeatureEnabled = useFeature(FORMS_FEATURE);

  const isMarketingFeatureEnabled = useFeature(MARKETING_FEATURE).on;

  const isRewardsFeatureEnabled = useFeature(REWARDS_FEATURE).on;

  useEffect(() => {
    if (user && (user as any)?.avatar_url) {
      setUserAvatar((user as any).avatar_url as any);
    }
  }, [user]);

  const goToDocument = () => {
    router.history.push('/document');
  };

  const getImage = (id) => {
    const currentImage = organizationImages.find((org) => org.id === id);

    if (currentImage) return currentImage.image;
    return '/img/defaultOrg.png';
  };

  return (
    <>
      <div className="sidebar sidebarLeft flex">
        <div
          onClick={() => setIsOpen(false)}
          className={classNames(
            'fixed inset-0 z-20 bg-black opacity-50 transition-opacity lg:hidden',
            isOpen ? 'block' : 'hidden',
          )}
        />

        <div
          className={classNames(
            'fixed inset-y-0 left-0 z-30 flex w-64 transform flex-col overflow-hidden bg-black transition duration-300 lg:static lg:inset-0 lg:translate-x-0',
            isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in',
          )}>
          {isLoggedIn && (
            <div className="mt-8 flex flex-col lg:hidden">
              <div className="mb-6 flex w-full justify-between px-6">
                <div className="flex items-center">
                  <div className="rounded-full border-[3px] border-primary-300">
                    <Avatar
                      alt="User profile"
                      src={
                        typeof userAvatar !== 'undefined'
                          ? userAvatar
                          : '/img/user_placeholder.png'
                      }
                      className="h-[25px] w-[25px]"
                    />
                  </div>

                  <p className="relative ml-4 text-white">
                    {(user as any)?.name?.split(' ')[0] || (user as any)?.name}
                  </p>
                </div>
                <div onClick={() => setIsUserMenuOpen((prev) => !prev)}>
                  {!isUserMenuOpen ? (
                    <ChevronDownIcon color="#FFF" fontSize={24} />
                  ) : (
                    <ChevronUpIcon color="#FFF" fontSize={24} />
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  'px-6',
                  isUserMenuOpen ? 'flex flex-col ' : 'hidden',
                )}>
                <Link
                  className="text-white"
                  to={{
                    pathname: 'profile',
                  }}>
                  {t(translations.COMMON.PROFILE)}
                </Link>
                <button
                  onClick={() => handleLogout()}
                  className="mb-4 mt-4 border-none bg-none text-left text-white">
                  {t(translations.COMMON.LOGOUT)}
                </button>
              </div>
            </div>
          )}

          <div className="my-16 hidden items-center justify-center lg:flex">
            <div className="flex items-center">
              {/* Brand */}
              <Logo
                onClick={() => router.push('/document')}
                wLogo={logo}
                type={LogoType.white}
                width="125px"
                height="42px"
              />
            </div>
          </div>

          <hr className="mb-4 border border-gray-400 lg:min-w-full" />

          <div className="sidebarInner flex-1 mb-4 overflow-y-scroll">
            {isLoggedIn && (
              <div className="block overflow-x-hidden">
                <div className="flex flex-col overflow-x-hidden px-6 pb-4 pt-1 text-xs no-underline lg:min-w-full">
                  <div className="flex w-full justify-between">
                    <div className="flex items-center">
                      <Avatar
                        src={getImage(currentOrganization.id)}
                        alt="Profile Avatar"
                      />
                      <p className="ml-4 text-md text-white">
                        {currentOrganization.translation
                          ? t(currentOrganization.name)
                          : currentOrganization.name}
                      </p>
                    </div>
                    <div
                      className="relative top-1 ml-auto cursor-pointer"
                      onClick={() => setStatsOpened()}>
                      {!statsOpened ? (
                        <ChevronDownIcon color="#FFF" fontSize={24} />
                      ) : (
                        <ChevronUpIcon color="#FFF" fontSize={24} />
                      )}
                    </div>
                  </div>
                  {statsOpened && (
                    <AccountSubscription
                      t={t}
                      color={primaryColor}
                      accountLimits={accountLimits}
                      organizations={organizations?.length}
                      showBasicCertificates={showBasicCertificates}
                    />
                  )}
                </div>
                {/* Divider */}
                <hr className="mb-4 border border-gray-400 lg:min-w-full" />
              </div>
            )}

            {/* Create Document Desktop */}
            {isLoggedIn && (
              <div className="px-4">
                <Button
                  className="mb-4 w-full text-white"
                  isFullWidth
                  color={primaryColor}
                  onClick={() => goToDocument()}
                  size="sm"
                  variant="solid">
                  {t(translations.SIDEBAR.CREATE_DOCUMENT)}
                </Button>
              </div>
            )}

            {!isLoggedIn && (
              <>
                <div className="md my-16 px-4 lg:hidden">
                  <p className="m-auto cursor-pointer text-center text-paragraph text-white">
                    <a href="/login">{t(translations.COMMON.LOGIN)}</a>
                  </p>
                  <Button
                    className="my-4 w-full text-white"
                    isFullWidth
                    color={primaryColor}
                    onClick={(e) => {
                      e?.preventDefault();
                      window.location.href = '/signup';
                    }}
                    size="sm"
                    variant="solid">
                    {t(translations.COMMON.REGISTER)}
                  </Button>
                </div>

                <hr className="mb-4 border border-gray-400 lg:hidden lg:min-w-full" />
              </>
            )}

            <nav className="mt-2">
              {isLoggedIn && (
                <>
                  <SidebarItem
                    borderColor={primaryColor}
                    label={t(translations.SIDEBAR.INBOX)}
                    id="inbox"
                    link="/inbox"
                    isActive={router.pathname.includes('inbox')}
                  />
                  <SidebarItem
                    borderColor={primaryColor}
                    label={t(translations.SIDEBAR.ORGANIZATIONS)}
                    id="organizaciones"
                    link="/organizations"
                    isActive={router.pathname.includes('organizations')}
                  />
                  <SidebarItem
                    borderColor={primaryColor}
                    label={t(translations.SIDEBAR.FOLDERS)}
                    id="carpetas"
                    link="/folders"
                    isActive={
                      router.pathname.includes('folders') &&
                      !router.pathname.includes('organization')
                    }
                  />
                  <SidebarItem
                    borderColor={primaryColor}
                    label={t(translations.SIDEBAR.CONTACTS)}
                    id="contacts"
                    link="/contacts"
                    isActive={
                      router.pathname.includes('folders') &&
                      !router.pathname.includes('organization')
                    }
                  />
                </>
              )}
              <SidebarItem
                borderColor={primaryColor}
                label={t(translations.SIDEBAR.VERIFICATION)}
                id="verificacion"
                link="https://blockchain.cincel.digital/"
                isExternal
                isActive={router.pathname.includes('verificacion')}
              />
              <SidebarItem
                borderColor={primaryColor}
                label={t(translations.SIDEBAR.INTEGRATIONS)}
                id="integraciones"
                link="/integrations"
                isActive={router.pathname.includes('integrations')}
              />
              {isFormFeatureEnabled && (
                <SidebarItem
                  borderColor={primaryColor}
                  label={t(translations.SIDEBAR.FORMS)}
                  id="forms"
                  link="/forms"
                  isActive={router.pathname.includes('forms')}
                />
              )}

              <SidebarItem
                borderColor={primaryColor}
                dropdown
                isExternal
                label={t(translations.SIDEBAR.HELP_CENTER)}
                id="help"
                link="https://ayuda.cincel.digital/"
                isOpen={router.pathname.includes('partners')}
                items={
                  [
                    {
                      link: 'https://ayuda.cincel.digital/preguntas-frecuentes',
                      label: t(translations.SIDEBAR.FAQ),
                    },
                    {
                      link: 'https://ayuda.cincel.digital/gu%C3%ADas-r%C3%A1pidas',
                      label: t(translations.SIDEBAR.GUIDES),
                    },
                    {
                      link: 'https://ayuda.cincel.digital/kb-tickets/new',
                      label: t(translations.SIDEBAR.SUPPORT),
                    },
                  ] as any
                }
              />
              {isMarketingFeatureEnabled && user?.role === 'marketing' ? (
                <SidebarItem
                  borderColor={primaryColor}
                  dropdown
                  label={t(translations.SIDEBAR.MARKETING)}
                  id="admin"
                  isActive={router.pathname.includes('marketing')}
                  items={[
                    {
                      link: '/marketing',
                      label: t(translations.COMMON.CREATE),
                    },
                    {
                      link: '/marketing/rewards',
                      label: t(translations.SIDEBAR.MARKETING_REWARDS),
                    },
                  ] as any}
                />
              ) : null}
              {isRewardsFeatureEnabled && isRewardsAvailable ? (
                <SidebarItem
                  borderColor={primaryColor}
                  label={t(translations.SIDEBAR.REWARDS)}
                  id="rewards"
                  isActive={
                    router.pathname.includes('rewards') &&
                    !router.pathname.includes('marketing')
                  }
                  link='/rewards'
                />
              ) : null}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
