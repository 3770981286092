import React, { useCallback } from 'react';
import fp from 'lodash/fp';

export enum LogoColor {
  default = '#060606',
  white = '#fff',
}

export enum LogoType {
  symbol = 'symbol',
  wordmark = 'wordmark',
  complete = 'complete',
  white = 'white',
}

export interface LogoProps {
  /**
   * Specify the color of the logo.
   */
  color?: LogoColor;
  /**
   * Type of the logo element.
   */
  type?: LogoType;
  /**
   * Sets the width of the logo in pixels
   */
  width?: string;
  /**
   * Sets the height of the logo in pixels
   */
  height?: string;
  /**
   * Component logo.
   */
  logo?: File | string;
  /**
   * Component white logo.
   */
  wLogo?: File | string;
  /**
   * On click event handler.
   */
  onClick?: () => void;
}

type Type = {
  symbol?: () => JSX.Element;
  wordmark?: () => JSX.Element;
  complete: () => JSX.Element;
  white: () => JSX.Element;
};

export const Logo: React.FC<LogoProps> = ({
  logo = '/img/logo.svg',
  wLogo = '/img/logo-blanco.svg',
  width = '221px',
  height = '42px',
  type = 'complete',
  onClick = () => {}
}) => {
  const getLogo = useCallback(() => {
    if (type === 'complete') return fp.isString(logo) ? logo : URL.createObjectURL(logo as File);
    return fp.isString(wLogo) ? wLogo : URL.createObjectURL(wLogo as File);
  }, [logo, wLogo]);

  return (
    <div
      className="flex items-center justify-center"
      onClick={onClick}
      style={{ maxHeight: height, minHeight: height }}
    >
      <img
        className="object-contain"
        src={getLogo()}
        alt="Logo"
        style={{ height, width }}
      />
    </div>
  );
};
