import { useMemo } from 'react';
import { CINCELClient } from '@cincel/client';

export const tokenName = 'tokenCincel';

export function useClient({ url = '', token = '' }) {
  const newToken: string = token || (localStorage.getItem(tokenName) as string);
  const client = useMemo(
    () =>
      new CINCELClient({
        baseURL: url,
        token: newToken as string,
      }),
    [newToken, url],
  );

  return client;
}
