import fp from 'lodash/fp';
import React, { useCallback } from 'react';
import ReactSelect from 'react-select';

import { getCustomCSS, getSelectComponents, SelectSize } from './helpers';
import {
  ISelectAtomicOption,
  ISelectAtomicValue,
  ISelectBase,
  ISelectOptions,
  ISelectValue,
} from './typings';

export interface ISelectProps extends ISelectBase {
  /**
   * Select field default value.
   */
  defaultValue?: ISelectAtomicValue;
  /**
   * If `true`, this field will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, it will display a loading helper.
   */
  isLoading?: boolean;
  /**
   * If `true`, the field will be multi-select.
   */
  isMulti?: boolean;
  /**
   * If `true`, it will have a custom red `*`.
   */
  isRequired?: boolean;
  /**
   * If `true`, the field will have rounded borders.
   */
  isRounded?: boolean;
  /**
   * Default placement of the menu in relation to the control, 'auto' will flip
   * when there isn't enough space below the control.
   */
  menuPlacement?: 'auto' | 'bottom' | 'top';
  /**
   * On change event handler.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (v: any) => void;
  /**
   * Select options.
   */
  options?: ISelectAtomicOption[];
  /**
   * Select placeholder.
   */
  placeholder?: string;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Select size. Can be one of the following options:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  size?: keyof typeof SelectSize;
  /**
   * Current pagination value.
   */
  value?: ISelectAtomicValue;
}

export const Select: React.FC<ISelectProps> = (props): JSX.Element => {
  const {
    defaultValue,
    className,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isRequired = false,
    isRounded = true,
    menuPlacement = 'bottom',
    options,
    onChange,
    placeholder,
    primaryColor,
    size = 'sm',
    value,
    ...rest
  } = props;

  const handleOnChange = useCallback((o: ISelectValue) => {
    const aux = fp.isArray(o) ? o.map((i) => i?.value) : fp.get('value')(o);
    if (!fp.isNil(onChange)) onChange(aux);
  }, []);

  const getValue = useCallback(
    (v?: ISelectAtomicValue) => {
      if (!fp.isEmpty(options) && !fp.isNil(v)) {
        if (!isMulti) return options?.find((opt) => fp.get('value')(opt) === v);
        return options?.filter(
          (opt) => (v as (number | string)[])?.indexOf(opt?.value) >= 0,
        );
      }
      return isMulti ? [] : undefined;
    },
    [options],
  );

  return (
    <ReactSelect
      className={className}
      components={getSelectComponents(size)}
      isDisabled={isDisabled || isLoading}
      isLoading={isLoading}
      isMulti={isMulti}
      menuPlacement={menuPlacement}
      onChange={handleOnChange}
      options={options as unknown as ISelectOptions}
      placeholder={placeholder}
      styles={getCustomCSS(primaryColor, isRequired, isRounded, size)}
      value={getValue(value || defaultValue) as ISelectAtomicValue | undefined}
      {...rest}
    />
  );
};
