/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useRouter } from 'hooks/useRouter';
import cn from 'classnames';
export const SidebarItem = ({
  borderColor,
  link = '#',
  id,
  label,
  dropdown = false,
  items = [],
  isOpen = false,
  isExternal = false,
  isActive = false,
}) => {
  const router = useRouter();
  const [active, setActive] = useState(isOpen);

  const navigate = (link) => {
    if (isExternal) {
      return window.open(link, '_blank');
    }
    router.history.push(link);
  };

  return (
    <>
      {!dropdown && (
        <a
          onClick={() => navigate(link)}
          className={cn(
            'flex items-center mt-2 px-6 py-2 text-gray-100 bg-gray-600 bg-opacity-25 border-l-4 cursor-pointer duration-200',
            isActive
              ? 'bg-gray-300 bg-opacity-25 text-gray-100'
              : 'border-gray-600 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100',
          )}
          style={(isActive ? { borderColor } : {})}
        >
          <div className="flex gap-4">
              <img
                className="text-white w-[25px] h-[25px]"
                src={`/img/menu-${id}.svg`}
                alt={`menu-${id}.svg`}
                width="25"
                height="25"
              />

            <p className="inline-block flex-1 text-basic-white w-full">{label}</p>
          </div>
        </a>
      )}
      {dropdown && (
        <div>
          <div
            className={cn(
              'flex gap-4 items-center mt-2 px-6 py-2 text-gray-100 bg-gray-600 bg-opacity-25 border-l-4 cursor-pointer duration-200',
              isActive
                ? 'bg-gray-300 bg-opacity-25 text-gray-100'
                : 'border-gray-600 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100',
            )}
            style={(isActive ? { borderColor } : {})}
          >
              <img
                className="text-white w-[25px] h-[25px]"
                src={`/img/menu-${id}.svg`}
                alt={`menu-${id}.svg`}
                width="25"
                height="25"
              />
            <p
              className="inline-block flex-1 text-basic-white w-full"
              onClick={() => navigate(link)}>
              {label}
            </p>
            {dropdown && (
              <div onClick={() => setActive((prev) => !prev)}>
                <i
                  className={cn(
                    'text-basic-white',
                    active ? 'fas fa-chevron-up' : 'fas fa-chevron-down',
                  )}></i>
              </div>
            )}
          </div>
          {active && (
            <div className="pl-7 w-full">
              <ul className="flex flex-col list-none md:flex-col md:min-w-full">
                {items.map((item: any, i) => {
                  return (
                    <li
                      key={i}
                      className={cn(
                        'flex items-center mt-2 px-6 py-2 text-gray-100 bg-gray-600 bg-opacity-25 border-l-4 cursor-pointer duration-200',
                        isActive
                          ? 'bg-gray-300 bg-opacity-25 text-gray-100'
                          : 'border-gray-600 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100',
                      )}
                      style={(isActive ? { borderColor } : {})}
                    >
                      <a
                        href={item.link}
                        target={item.link.includes('http') ? '_blank' : '_self'}
                        rel="noreferrer">
                        <div className="w-full">
                          <p className="inline-block ml-6 mt-1 text-basic-white text-paragraph-sm">
                            {item.label}
                          </p>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};
