import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useInterval } from 'hooks/useInterval';
import { tokenName } from 'config/constants';
import { WLContext } from '@contexts';

const IDLE_TIME_MS = 3600000;

export const INITIAL_ROUTE = '/';

export const WrapperRoute = ({
  component: Component,
  isPrivate,
  policy,
  path,
  ...restOfProps
}) => {
  const { isFetching } = useContext(WLContext);

  const isAuthenticated = localStorage.getItem(tokenName);

  const history = useHistory();

  useInterval(() => {
    if (isAuthenticated) {
      history.go(0);
    }
  }, IDLE_TIME_MS);

  if (isFetching && !location.pathname.includes('sign_invitation')) return null;

  if (isPrivate && !isAuthenticated) return <Redirect to="/login" />;
  if (path === '/') return <Redirect to="/document" />;

  return (
    <Route {...restOfProps}>
      {(props) => <Component {...props} />}
    </Route>
  );
};
