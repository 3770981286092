import React, { HTMLProps } from "react";
import { InputField } from "@design-system/inputs";
import { useForm } from "react-hook-form";
import { Form } from "./components/Form";
import { translations } from 'translations';
import { useTranslation } from "react-i18next";
import { Button } from "@design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DEFAULT_VALUES,
  IRecoveryPasswordFormFields,
  RecoveryPasswordFormValidationSchema
} from "./helpers";

export interface IRecoveryPasswordFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Form default values.
   */
  defaultValues?: IRecoveryPasswordFormFields;
  /**
   * On submit action handler.
   */
  onSubmit: (v: IRecoveryPasswordFormFields) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const RecoveryPasswordForm: React.FC<IRecoveryPasswordFormProps> = (
  props
): JSX.Element => {
  const {
    className,
    defaultValues = DEFAULT_VALUES,
    onSubmit,
    primaryColor,
    ...rest
  } = props;

  const {
    control,
    formState,
    handleSubmit
  } = useForm<IRecoveryPasswordFormFields>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: yupResolver(RecoveryPasswordFormValidationSchema),
  });

  const { t } = useTranslation();

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="email"
        placeholder={t(translations.INPUTS.EMAIL)}
        primaryColor={primaryColor}
        size="md"
        type="email"
      />

      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="new_password"
        placeholder={t(translations.INPUTS.PASSWORD)}
        primaryColor={primaryColor}
        size="md"
        type="password"
      />

      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="confirmPassword"
        placeholder={t(translations.INPUTS.CONFIRM_PASSWORD)}
        primaryColor={primaryColor}
        size="md"
        type="password"
      />

      <Button
        className="w-full md:w-[250px]"
        color={primaryColor}
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        size="sm"
        type="submit">
        {t(translations.COMMON.STORE_PASSWORD)}
      </Button>
    </Form>
  )
}
