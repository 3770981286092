import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { css } from '@emotion/css';

export type PanelDataType = {
  id: string | number;
  key: string;
  name: string;
  target: string;
  isActive: boolean;
  component: React.ReactNode;
};

type LabelProps = {
  classNames?: string;
  id: string | number;
  keyName: string;
  target: string;
  title: string;
  isActive: boolean;
  setOpened: (id: string | number) => void;
  color: string;
  width: string;
};

const Label: React.FC<LabelProps> = ({
  classNames = '',
  id,
  keyName,
  target,
  title,
  isActive = false,
  color,
  setOpened,
  width = '100%',
}) => {
  const { t } = useTranslation();
  return (
    <li
      className={cn(classNames, 'nav-item')}
      style={{
        width,
      }}
      role="presentation">
      <div
        className={cn(
          'relative top-[25px] z-10 ml-[4px] mr-1 h-2 w-2 rounded-full border text-caption',
          isActive ? '' : 'bg-gray-400 hover:bg-gray-400',
        )}
        style={isActive ? { background: color } : {}}
      />
      <a
        href={`#${target}`}
        className={cn(
          isActive
            ? css`
                border-bottom: 2px solid ${color};
              `
            : '',
          'border-transparent hover:border-transparent focus:border-transparent nav-link  mb-2 block truncate text-ellipsis border-x-0  border-t-0 border-b-4 px-6 py-3 text-paragraph-sm font-medium leading-tight hover:bg-gray-100',
        )}
        id={keyName}
        data-bs-toggle="pill"
        data-bs-target={`#${target}`}
        role="tab"
        aria-controls={target}
        aria-selected={isActive ? 'true' : 'false'}
        onClick={() => {
          setOpened(id);
        }}>
        {t(title)}
      </a>
    </li>
  );
};

export type TabsPanel = {
  color: string;
  panels: PanelDataType[];
  panelClasses?: string;
};

export const TabsPanel: React.FC<TabsPanel> = ({
  color,
  panels,
  panelClasses = '',
}) => {
  const [opened, setOpened] = useState<string | number>('1');

  const handleOpened = (id: string | number) => {
    setOpened(id);
  };
  return (
    <div className="flex w-full flex-col items-center justify-center overflow-x-hidden">
      <ul
        className="nav nav-tabs flex  w-full list-none flex-row overflow-x-scroll border-b-0 pl-0"
        id="tabs-tab"
        role="tablist">
        {panels.map((panel) => {
          const isActive = opened === panel.id;
          return (
            <Label
              classNames="text"
              id={panel.id}
              key={panel.id}
              keyName={panel.key}
              target={panel.target}
              title={panel.name}
              isActive={isActive}
              setOpened={handleOpened}
              color={color}
              width={
                panels.length === 1
                  ? '100%'
                  : panels.length === 2
                  ? '50%'
                  : isActive
                  ? '50%'
                  : '33%'
              }
            />
          );
        })}
      </ul>
      <div
        className={cn('tab-content w-full', panelClasses)}
        id="tabs-tabContent">
        {panels.map((panel) => {
          return (
            <div
              key={panel.id}
              className={classNames(
                'fade tab-pane',
                panel.isActive && 'active show',
              )}
              id={panel.target}
              role="tabpanel">
              {panel.component}
            </div>
          );
        })}
      </div>
    </div>
  );
};
