export const translations = {
  COMMON: {
    ADD: 'common.add',
    ADMIN: 'common.admin',
    ADVANCED: 'common.advanced',
    ACCEPT: 'common.accept',
    ALL: 'common.all',
    AND_THE: 'common.andThe',
    ASK_LIFE_PROOF: 'common.askLifeProof',
    AUDIT: 'common.audit',
    AUTOGRAPH: 'common.autograph',
    BACK: 'common.back',
    BLOCKCHAIN_PROCESSING: 'common.blockchainProcessing',
    CANCEL: 'common.cancel',
    CERTIFICATE_TYPE: 'common.certificateType',
    CATEGORIES: 'common.categories',
    CHANGE: 'common.change',
    CINCEL_TITLE: 'common.cincelTitle',
    COMMENTS: 'common.comments',
    CONTINUE: 'common.continue',
    CREATE: 'common.create',
    DATE: 'common.date',
    DELETE_DOCUMENT: 'common.deleteDocument',
    DELETE: 'common.delete',
    DESCRIPTION: 'common.description',
    DETAIL: 'common.detail',
    DOWNLOAD: 'common.download',
    DRAG_FILE: 'common.dragFile',
    EDIT: 'common.edit',
    EMAIL: 'common.email',
    ENGLISH: 'common.english',
    FILE: 'common.file',
    GOOGLE_LOGIN: 'common.googleLogin',
    HISTORY: 'common.history',
    I_ACCEPT: 'common.iAccept',
    INVITEE: 'common.invitee',
    LOAD_MORE: 'common.loadMore',
    LOGIN: 'common.login',
    LOGOUT: 'common.logout',
    MORE_INFO: 'common.moreInfo',
    NEW_STAGE: 'common.newStage',
    NO_DOCUMENTS: 'common.noDocuments',
    NO_SELECTED_FILES: 'common.noSelectedFiles',
    NO_SIGN: 'common.noSign',
    OBTAIN: 'common.obtain',
    OWNER: 'common.owner',
    PARTIALLY_SIGNED: 'common.partiallySigned',
    PRIVACY_POLICY: 'common.privacyPolicy',
    PROFILE: 'common.profile',
    QUOTE: 'common.quote',
    RECOMMENDED_DIMENSIONS: 'common.recommendedDimensions',
    REGISTER: 'common.register',
    REJECT: 'common.reject',
    SAVE: 'common.save',
    SEARCH: 'common.search',
    SERACH_BY_NAME: 'common.searchByName',
    SEND: 'common.send',
    SEND_REMINDER: 'common.sendReminder',
    SHARE: 'common.share',
    SIGN_UP: 'common.signUp',
    SIGN: 'common.sign',
    SIGNATURE_TYPE: 'common.signatureType',
    SIGNED: 'common.signed',
    SIGNER: 'common.signer',
    SIGNERS: 'common.signers',
    SPANISH: 'common.spanish',
    STAGE: 'common.stage',
    STARTED: 'common.started',
    STATUS: 'common.status',
    STORE_PASSWORD: 'common.storePassword',
    SUCCESSFULLY: 'common.successfully',
    TERMS: 'common.terms',
    UNSIGNED: 'common.unsigned',
    UPDATE: 'common.update',
    VALIDATE: 'common.validate',
    WANT_TO_CONTINUE: 'common.wantToContinue',
    YES: 'common.yes',
    OBSERVERS: 'common.observers',
    OBSERVER: 'common.observer',
    PREVIEW: 'common.preview',
    FORM_DATA: 'common.formData',
    SIGNER_INFORMATION: 'common.signerInformation',
    NAME: 'common.name',
    CREATED_AT: 'common.createdAt',
    FIELDS: 'common.fields',
  },
  CONTACTS: {
    ADD_TITLE: 'contacts.addTitle',
    BUTTON_ADD: 'contacts.buttonAdd',
    CSV_HELPER: 'contacts.csvHelper',
    CSV_TITLE: 'contacts.csvTitle',
    DELETE_SUCCESS: 'contacts.deleteSuccess',
    STATUS_CODE_409: 'contacts.statusCode409',
    HEADING: 'contacts.heading',
    PATCH_SUCCESS: 'contacts.patchSuccess',
    POST_SUCCESS: 'contacts.postSuccess',
    SUBHEADING: 'contacts.subHeading',
    TABLE_EMPTY: 'contacts.table.empty',
    TABLE_HEADING_CREATED_AT: 'contacts.table.heading.createdAt',
    TABLE_HEADING_EMAIL: 'contacts.table.heading.email',
    TABLE_HEADING_NAME: 'contacts.table.heading.name',
    TABLE_HEADING_UPDATED_AT: 'contacts.table.heading.updatedAt',
    UPDATE_TITLE: 'contacts.updateTitle',
    UPLOAD_CSV: 'contacts.uploadCsv',
  },
  INBOX: {
    IN_PROCESS: 'inbox.inProcess',
    SIGNED: 'inbox.signed',
  },
  INPUTS: {
    HELPER_TEXT: 'inputs.helperText',
    NEW_FORM: 'inputs.newForm',
    CONFIRM_PASSWORD: 'inputs.confirmPassword',
    DOCUMENT_CHECKBOX: 'inputs.documentCheckbox',
    DOCUMENT_NAME: 'inputs.documentName',
    DONT_SHOW_AGAIN: 'inputs.dontShowAgain',
    EMAIL: 'inputs.email',
    ENTER_EMAIL: 'inputs.enterEmail',
    FOLDER_NAME: 'inputs.folders.name',
    NAME: 'inputs.name',
    NEW_ORGANIZATION_INPUT: 'inputs.newOrganization.input',
    NEW_ORGANIZATION_LABEL: 'inputs.newOrganization.label',
    NEW_PASSWORD: 'inputs.newPassword',
    PASSWORD_KEY: 'inputs.passwordKey',
    PASSWORD: 'inputs.password',
    REWARDS_AVAILABILITY: 'inputs.rewardsAvailability',
    SELECT_FOLDER: 'inputs.selectFolder',
    SELECT_LANGUAGE: 'inputs.selectLanguage',
    TEXT_FIELD_FORM_DEFAULT_VALUE: 'inputs.textFieldForm.defaultValue',
    TEXT_FIELD_FORM_FIELD_FLAGS_MULTILINE:
      'inputs.textFieldForm.fieldFlags.multiLine',
    TEXT_FIELD_FORM_FIELD_FLAGS_READ_ONLY:
      'inputs.textFieldForm.fieldFlags.readOnly',
    TEXT_FIELD_FORM_FIELD_FLAGS_REQUIRED:
      'inputs.textFieldForm.fieldFlags.required',
    TEXT_FIELD_FORM_FIELD_FLAGS_TITLE: 'inputs.textFieldForm.fieldFlags.title',
    TEXT_FIELD_FORM_FIELD_NAME: 'inputs.textFieldForm.fieldName',
    VERIFICATION_CODE_LABEL: 'inputs.verificationCode',
    SELECT_FORM: 'inputs.selectForm',
    SIGNER_INFORMATION: 'inputs.signerInformation',
    FORM_FILL: 'inputs.formFill',
    FORM_VALIDATE: 'inputs.formValidate',
  },
  ERRORS: {
    AT_LEAST_CHOOSE_ONE_ITEM: 'errors.atLeastChooseOneItem',
    MAX_ITEMS_ALLOWED_3: 'errors.maxItemsAllowed3',
    MAX_LENGTH_50: 'errors.maxLength50',
    MAX_LENGTH_100: 'errors.maxLength100',
    MAX_LENGTH_144: 'errors.maxLength144',
    MAX_LENGTH_255: 'errors.maxLength255',
    MAX_LENGTH_1000: 'errors.maxLength1000',
    FORM_NAME_REQUIRED: 'errors.forms.nameRequired',
    FORM_NAME_MAX_50: 'errors.forms.max50',
    BLOCKCHAIN_ERROR: 'errors.blockchainError',
    CONFIRM_PASSWORD: 'errors.confirmPassword',
    CREATE_DOCUMENT_ANNOTATION_ERROR: 'errors.createDocument.annotationError',
    CREATOR_OUT_LIFE_PROOF: 'errors.creatorOutLifeProof',
    DOCUMENT_FOLDER_REQUIRED: 'errors.documentFolderRequired',
    DOCUMENT_NAME_REQUIRED: 'errors.documentNameRequired',
    DOCUMENT_SIZE: 'errors.documentSize',
    EXISTING_SIGNATURE_FIELD: 'errors.existingSignatureField',
    GENERAL_EXISTING_SIGNATURE_FIELD: 'errors.generalExistingSignatureField',
    GENERAL_ERROR: 'errors.general',
    HASH_ERROR: 'errors.hashError',
    IMAGE_512: 'errors.image512',
    IMAGE_FORMAT: 'errors.imageFormat',
    IMAGE_SIZE: 'errors.imageSize',
    LIFE_PROOF_MAX_TRIES: 'errors.lifeProofMaxTries',
    MAX_FILE_SIZE: 'errors.maxFileSize',
    NETWORK_DEFAULT: 'errors.network.default',
    NO_BASIC_CERTIFICATES: 'errors.noBasicCertificates',
    NO_SMART_CERTIFICATES: 'errors.noSmartCertificates',
    NOM_ERROR: 'errors.nomError',
    ONLY_PNG_FILES: 'errors.onlyPngFiles',
    ONLY_CSV_FILES: 'errors.onlyCsvFiles',
    ONLY_POSITIVE_NUMBERS: 'errors.onlyPositiveNumbers',
    OUT_OF_SERVICE: 'errors.outOfService',
    PASSWORD_LENGTH: 'errors.passwordLength',
    PASSWORDS_DONT_MATCH: 'errors.passwordsDontMatch',
    PDF_SIGNATURE_CANT_ADD_SIGN: 'errors.pdfSignatureCantAddSign',
    PDF_SIGNATURE_ERROR: 'errors.pdfSignatureError',
    PDF_SIGNATURE_GENERAL: 'errors.pdfSignatureGeneral',
    PDF_SIGNATURE_UPDATE_ERROR: 'errors.pdfSignatureUpdateError',
    PKCS7: 'errors.pkcs7',
    REQUIRED: 'errors.required',
    REQUIRED_EMAIL: 'errors.requiredEmail',
    REQUIRED_NAME: 'errors.requiredName',
    REQUIRED_PASSWORD: 'errors.requiredPassword',
    REQUIRED_VERIFICATION_CODE: 'errors.requiredVerificationCode',
    RESTORE_PASSWORD_ERROR: 'errors.restorePasswordError',
    SEGURIDATA_CIN01: 'errors.seguridata.cin01',
    SEGURIDATA_CIN02: 'errors.seguridata.cin02',
    SEGURIDATA_CIN03: 'errors.seguridata.cin03',
    SEGURIDATA_CIN06: 'errors.seguridata.cin06',
    SEGURIDATA_CIN09: 'errors.seguridata.cin09',
    SIGNATURE_ERROR: 'errors.signatureError',
    SIGNUP_ALREADY_REGISTERED: 'errors.signup.alreadyRegistered',
    STORAGE_LIMIT: 'errors.storageLimit',
    VALID_EMAIL_CHARACTERS: 'errors.validEmailCharacters',
    VALID_EMAIL: 'errors.validEmail',
    VALID_URL: 'errors.validUrl',
    VERIFICATION_CODE_MAX_DIGITS_ERROR: 'errors.verificationCodeMaxDigits',
    WHITELABEL_401: 'errors.whitelabel.401',
    WHITELABEL_402: 'errors.whitelabel.402',
    WHITELABEL_404: 'errors.whitelabel.404',
    WHITELABEL_413: 'errors.whitelabel.413',
    WHITELABEL_415: 'errors.whitelabel.415',
    WHITELABEL_500: 'errors.whitelabel.500',
    INVITATIONS_ERROR: 'errors.invitations',
    GENERAL_MIN_LENGTH: 'errors.general.minLength',
    GENERAL_MAX_LENGTH: 'errors.general.maxLength',
    GENERAL_REQUIRED: 'errors.general.required',
    NO_TEMPLATE_FIELDS: 'errors.formTemplates.noFields',
    NO_PDF: 'errors.formTemplates.noPdf',
    FORM_TEMPLATE_UPDATE_ERROR: 'errors.formTemplates.update',
    FORM_TEMPLATE_CREATE_ERROR: 'errors.formTemplates.create',
    FORM_TEMPLATE_DELETE_ERROR: 'errors.formTemplates.delete',
    EXISTING_EMAIL: 'errors.general.existingEmail',
    FORM_CREATED: 'error.forms.create',
    FORM_FILLED: 'error.forms.filled',
    NO_CERITIFICATES_AVAILABLE: 'errors.noCertificatesAvailable',
  },
  SUCCESS: {
    DOCUMENT_DELETED: 'success.folders.documentDeleted',
    DOCUMENT_FOLDER_CHANGED: 'success.folders.documentFolderChanged',
    DOCUMENT_SIGNED: 'success.documentSigned',
    FOLDERS_CREATED: 'success.folders.created',
    FOLDERS_DELETE: 'success.folders.delete',
    FOLDERS_EDITED: 'success.folders.edited',
    LIFE_PROOF_SUCCESS: 'success.lifeProofSuccess',
    ORGANIZATION_CREATED: 'success.organizationCreated',
    ORGANIZATION_EDITED: 'success.organizationEdited',
    ORGANIZATION_INVITATION_DELETED: 'success.organizationInvitationDeleted',
    ORGANIZATION_INVITATION_SENT: 'success.organizationInvitationSent',
    ORGANIZATION_USER_DELETED: 'success.organizationUserDeleted',
    PROFILE_EDITED: 'success.profile.edited',
    REMINDER_SENT: 'success.reminderSent',
    RESET_PASSWORD_SENT: 'success.resetPassword.sent',
    RESET_PASSWORD_SUCCESS: 'success.ressetPassword.success',
    SIGNUP_SUCCESS: 'success.signup.message',
    WHITELABEL_200: 'success.whitelabel.200',
    INVITATION_CREATED_OBSERVER: 'success.invitationCreated.observer',
    INVITATION_UPDATED_OBSERVER: 'success.updatedCreated.observer',
    INVITATION_DELETED_OBSERVER: 'success.deletedCreated.observer',
    INVITATION_CREATED_SIGNER: 'success.invitationCreated.signer',
    INVITATION_UPDATED_SIGNER: 'success.updatedCreated.signer',
    INVITATION_DELETED_SIGNER: 'success.deletedCreated.signer',
    FORM_TEMPLATE_UPDATE_SUCCESS: 'success.formTemplates.update',
    FORM_TEMPLATE_CREATE_ERROR: 'success.formTemplates.create',
    FORM_CREATED: 'success.forms.create',
    FORM_FILLED: 'success.forms.filled',
    FORM_FILLED_UNREGISTERED_USER: 'success.forms.filledUnregisteredUser',
  },
  MODALS: {
    ADD_FROM_CONTACTS: 'modals.addFromContacts',
    ADD_FROM_NAME_AND_EMAIL: 'modals.AddFromNameAndEmail',
    ADD_OBSERVER_TITLE: 'modals.observer.createTitle',
    ADD_SIGNER_TO_DIRECTORY: 'modals.addSignerToDirectory',
    CHOOSE_SIGNERS: 'modals.chooseSigners',
    EDIT_OBSERVER_TITLE: 'modals.observer.editTitle',
    NEW_FORM: 'modals.newForm',
    CHANGE_FOLDER_TITLE: 'modals.changeFolder.title',
    DELETE_DOCUMENT_MESSAGE: 'modals.deleteDocument.message',
    DELETE_DOCUMENT_TITLE: 'modals.deleteDocument.title',
    DELETE_FOLDER: 'modals.folders.deleteFolder',
    DOCUMENT_CREATED_SUCCESS: 'modals.documentCreated.message',
    EDIT_FOLDER: 'modals.folders.editFolder',
    EXISTING_ANNOTATIONS: 'modals.existingAnnotations.title',
    LIFE_PROOF_CLOSING: 'modals.lifeProof.closing',
    LIFE_PROOF_MESSAGE: 'modals.lifeProof.message',
    LIFE_PROOF_TITLE: 'modals.lifeProof.title',
    LIFE_PROOF_VALIDATING: 'modals.lifeProof.validating',
    NEW_FOLDER: 'modals.folders.newFolder',
    NEW_STAGE_NAME: 'modals.newStage.name',
    NEW_STAGE_SUBTITLE: 'modals.newStage.subTitle',
    NEW_STAGE_TITLE: 'modals.newStage.title',
    ORGANIZATION_INVITATION: 'modals.organization.invitation',
    PDF_CONVERT_MESSAGE: 'modals.pdfConvert.message',
    PDF_CONVERT_TITLE: 'modals.pdfConvert.title',
    SIGNATURE_TITLE: 'modals.signatureTitle.title',
    SIGNER_ASK_DOCUMENTS: 'modals.signer.askDocuments',
    SIGNER_ASK_LIFE_PROOF: 'modals.signer.askFileProof',
    SIGNER_DOCUMENT: 'modals.signer.document',
    SIGNER_TITLE_ADD: 'modals.signer.titleAdd',
    SIGNER_TITLE_MODIFY: 'modals.signer.titleModify',
  },
  // COMPONENTS
  USER_DROPDOWN: {
    INVITATION_ACCEPTED: 'userDropdown.invitationAcepted',
    INVITATION_EMPTY: 'userDropdown.invitationEmpty',
    INVITATION_MESSAGE: 'userDropdown.invitationMessage',
    INVITATION_REJECTED: 'userDropdown.invitationRejected',
  },
  DROPZONE: {
    RECOMMENDED_SIZE: 'dropzone.recommendedSize',
    VALID_FILES: 'dropzone.validFiles',
  },
  SIDEBAR: {
    CREATE_DOCUMENT: 'sidebar.createDocument',
    CONTACTS: 'sidebar.contacts',
    FAQ: 'sidebar.faqs',
    FOLDERS: 'sidebar.folders',
    FORMS: 'sidebar.forms',
    GUIDES: 'sidebar.guides',
    HELP_CENTER: 'sidebar.helpCenter',
    INBOX: 'sidebar.inbox',
    INSURANCE: 'sidebar.insurance',
    INTEGRATIONS: 'sidebar.integrations',
    MANIFESTO: 'sidebar.manifesto',
    MARKETING: 'sidebar.administration',
    MARKETING_REWARDS: 'sidebar.rewardAdmin',
    ORGANIZATIONS: 'sidebar.organizations',
    REWARDS: 'sidebar.rewards',
    SUBS_CERT_BASIC: 'sidebar.certBasic',
    SUBS_CERT_SMART: 'sidebar.certSmart',
    SUBS_PLAN: 'sidebar.plan',
    SUBS_PROOF: 'sidebar.proof',
    SUBS_STORAGE: 'sidebar.storage',
    SUBS_USERS: 'sidebar.users',
    SUPPORT: 'sidebar.support',
    VERIFICATION: 'sidebar.verification',
  },
  PAGINATION: {
    RESULTS: 'pagination.results',
    SHOW: 'pagination.show',
  },
  CERTIFICATE_TYPES: {
    BASIC: 'certificateType.basic',
    BASIC_TEXT: 'certificateType.basicText',
    CHOOSE_CERTIFICATE: 'certificateType.chooseCertificate',
    INCLUDES: 'certificateType.includes',
    SMART: 'certificateType.smart',
    SMART_TEXT_ADOBE: 'certificateType.smartTextAdobe',
    SMART_TEXT_BITCOIN: 'certificateType.smartTextBitcoin',
    SMART_TEXT_NOM: 'certificateType.smartTextNom',
    SMART_TEXT_VERIFICATION: 'certificateType.smartTextVerification',
  },
  // PAGES
  AUTH_PAGES: {
    CONFIRM_ACCOUNT_TITLE: 'confirmAccount.title',
    LOGIN_FORGOT_PASSWORD: 'login.forgotPassword',
    LOGIN_NO_ACCOUNT: 'login.noAccount',
    LOGIN_REGISTER_FREE: 'login.registerFree',
    LOGIN_TITLE: 'login.title',
    RESET_PASSWORD_SUCCESS_SUBTITLE: 'resetPasswordSuccess.subtitle',
    RESET_PASSWORD_SUCCESS_TITLE: 'resetPasswordSuccess.title',
    RESET_PASSWORD_TITLE: 'resetPassword.title',
    SIGNUP_CERTIFICATES: 'signup.certificates',
    SIGNUP_CLICK: 'signup.click',
    SIGNUP_FINGERPRINTS: 'signup.fingerprints',
    SIGNUP_FREEPLAN_TITLE: 'signup.freeplanTitle',
    SIGNUP_FUNCTIONS: 'signup.functions',
    SIGNUP_HAVE_ACCOUNT: 'signup.haveAccount',
    SIGNUP_INTEGRATIONS: 'signup.integrations',
    SIGNUP_ISO: 'signup.iso',
    SIGNUP_LOGIN_HERE: 'signup.loginHere',
    SIGNUP_NOTARIZATION: 'signup.notarization',
    SIGNUP_SIGN: 'signup.sign',
    SIGNUP_TITLE: 'signup.title',
    VERIFICATION_CODE_ERROR_TOAST: 'verificationCode.errorToast',
    VERIFICATION_CODE_SUCCESS_TOAST: 'verificationCode.successToast',
  },
  DOCUMENT_PAGES: {
    CANT_SEND_MESSAGE: 'signDocument.cantSendMessage',
    CREATE_DOCUMENT_INVITATION_MESSAGE: 'createDocument.invitationMessage',
    CREATE_DOCUMENT_NO_NAME: 'createDocument.noName',
    CREATE_DOCUMENT_TITLE: 'createDocument.title',
    CREATE_DOCUMENT_VALID_FILES: 'createDocument.validFiles',
    DOCUMENT_VIEWPORT_GEO: 'documentViewport.geo',
    ENTER_CER: 'signDocument.enterCer',
    ENTER_KEY: 'signDocument.enterKey',
    EXISTING_ANNOTATIONS: 'existingAnnotations.message',
    HISTORY_INACTIVE: 'signDocument.historyInactive',
    HISTORY_NEW_VERSION: 'signDocument.historyNewVersion',
    HISTORY_SIGNED: 'signDocument.historySigned',
    NEW_COLOR: 'createDocument.newColor',
    NO_SIGNS: 'signDocument.noSigns',
    PARTIALLY_SIGNED: 'signDocument.partiallySign',
    SIGNATURE_TYPE: 'signDocument.signatureType',
    SIGNED: 'signDocument.signed',
    TOOLBAR_NO_SIGNERS: 'toolbar.noSigners',
    TOOLBAR_SIGN_CERTIFICATE_TYPE: 'toolbar.signCertificateType',
    WRITE_COMMENT: 'signDocument.writeComment',
    NO_OBSERVERS: 'createDocument.noObservers',
    NO_COMMENTS: 'signDocument.noComments',
  },
  FOLDERS_PAGES: {
    CHANGE_FOLDER: 'folders.changeFolder',
    CONFIRMATION: 'folders.confirmation',
    GENERAL: 'folders.general',
    MY_FOLDERS: 'folders.myFolders',
    NEW_FOLDER: 'folders.newFolder',
  },
  INTEGRATIONS: {
    TITLE: 'integrations.title',
  },
  REWARDS: {
    ADMIN_HEADING: 'rewards.admin.heading',
    BENEFIT_LABEL: 'rewards.benefit.label',
    BUTTON_CREATE_REWARD: 'rewards.button.create',
    BUTTON_EDIT_REWARD: 'rewards.button.edit',
    COMPANY_LOGO_LABEL: 'rewards.companyLogo.label',
    COMPANY_NAME_LABEL: 'rewards.companyName.label',
    COMPANY_SUMMARY_LABEL: 'rewards.companySummary.label',
    COUNTRIES_LABEL: 'rewards.countries.label',
    COUPON_LABEL: 'rewards.coupon.label',
    COVER_LABEL: 'rewards.cover.label',
    CREATE_SUCCESS: 'rewards.create.success',
    DELETE_SUCCESS: 'rewards.delete.success',
    DETAILS_LABEL: 'rewards.details.label',
    EDIT_HEADING: 'rewards.edit.heading',
    PRIORITY_LABEL: 'rewards.priority.label',
    TABLE_EMPTY: 'rewards.table.empty',
    TABLE_HEADING_COMPANY: 'rewards.table.heading.company',
    TABLE_HEADING_CATEGORIES: 'rewards.table.heading.categories',
    TABLE_HEADING_DATE: 'rewards.table.heading.date',
    TABLE_HEADING_PRIORITY: 'rewards.table.heading.priority',
    TABLE_HEADING_REWARD: 'rewards.table.heading.reward',
    TITLE: 'rewards.title',
    UPDATE_SUCCESS: 'rewards.update.success',
    URL_LABEL: 'rewards.url.label',
  },
  ORGANIZATIONS: {
    ACCEPT_MESSAGE: 'organizations.acceptMessage',
    CREATE_TITLE: 'organizations.createTitle',
    EDIT_ORGANIZATION: 'organizations.editOrganization',
    LOGO: 'organizations.logo',
    MY_ORGANIZATION: 'organizations.myOrganization',
    NEW: 'organizations.new',
    ROLE: 'organizations.rol',
    SELECT_ROLE: 'organizations.selectRole',
    TITLE: 'organizations.title',
    USERS_INVITES: 'organizations.usersInvites',
  },
  USER_PAGES: {
    ADMIN_COLOR: 'admin.color',
    ADMIN_LOGO: 'admin.logo',
    ADMIN_TITLE: 'admin.title',
    PROFILE_ACTION: 'profile.action',
    PROFILE_IMAGE: 'profile.image',
    PROFILE_PERSONAL_DATA: 'profile.personalData',
  },
  FORMS_PAGES: {
    TITLE: 'forms.title',
    NEW_FORM: 'forms.newForm',
    EDIT_TITLE: 'forms.editTitle',
    USE_FORM: 'forms.useForm',
    NO_SIGNERS: 'forms.noSigners',
    SIGN_TITLE: 'forms.signTitle',
  },
};
