import React, { useState, useRef, forwardRef, useContext } from 'react';
import { Button, Checkbox } from '@design-system';
import cn from 'classnames';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { isNil } from 'lodash';
import { WLContext } from '@contexts';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface IModalProps {
  /**
   * Label that will be displayed by the cancel button.
   * @type string | undefined
   */
  cancelText?: string;
  /**
   * Modal content elements.
   * @type React.ReactNode
   */
  children: React.ReactNode;
  /**
   * Custom CSS styles that will be added to the content div.
   * @type string
   */
  className?: string;
  /**
   * If `true`, the modal will hide when clicking outside the content.
   * @type boolean
   */
  closeOnClickOutside?: boolean;
  /**
   * If `true`, the modal will close after `onSuccess` method finish
   * its execution.
   * @type boolean
   */
  closeOnSuccess?: boolean;
  /**
   * In case that this modal manipulates a form, this prop helps the
   * submit button to identify the form that will be triggered.
   */
  formId?: string;
  /**
   * Funtion that will be executed when the user clicks on the close
   * button.
   * @type () => void
   */
  handleCancel?: () => void;
  /**
   * If `true`, the continue button will be disabled.
   * @type boolean
   */
  isDisabled?: boolean;
  /**
   * If `true`, the continue button will change to a loading stare.
   * @type boolean
   */
  isLoading?: boolean;
  /**
   * Function that will be executed when the user clicks on the
   * continue button.
   * @type (v?: bollean | string) => void
   */
  onSuccess?: (v?: boolean | string) => void;
  /**
   * If `true`, the modal will display a `not show again` checkbox.
   * @type boolean
   */
  persist?: boolean;
  /**
   * Function that will change the `showModal` state.
   * @type (v: bollean) => void
   */
  setShowModal: (v: boolean) => void;
  /**
   * If `true` it will render the continue button.
   * @type boolean
   */
  showButtons?: boolean;
  /**
   * If `true`, it will render the cancel button.
   * @type boolean
   */
  showCancel?: boolean;
  /**
   * If `true`, the modal will be open.
   * @type boolean
   */
  showModal: boolean;
  /**
   * Subtitle text.
   * @type string | undefined
   */
  subtitle?: string;
  /**
   * Title text.
   * @type string | undefined
   */
  title?: string;
  /**
   * Modal size @see https://tailwindcss.com/docs/width
   * @type string
   */
  width?: string;
}

export const Modal = forwardRef<HTMLDivElement, IModalProps>((props, ref) => {
  const { t } = useTranslation();
  const {
    cancelText = t(translations.COMMON.CANCEL),
    children,
    className,
    closeOnClickOutside = true,
    closeOnSuccess = true,
    formId,
    handleCancel = () => { },
    isDisabled = false,
    isLoading = false,
    onSuccess = () => { },
    persist = false,
    setShowModal,
    showButtons = true,
    showCancel = true,
    showModal,
    subtitle,
    title,
    width = 'normal',
  } = props;

  const node = useRef(null);

  const [checkboxActive, setCheckboxActive] = useState(true);

  const { color } = useContext(WLContext);

  const handleSuccess = () => {
    onSuccess(checkboxActive);
    if (closeOnSuccess) setShowModal(false);
  };

  useOnClickOutside(node, () => {
    if (closeOnClickOutside) {
      handleCancel();
      setShowModal(false);
    }
  });

  const onActive = () => {
    setCheckboxActive((prev) => !prev);
  };

  const onCancel = () => {
    if (handleCancel) handleCancel();
    if (setShowModal) setShowModal(false);
  };

  return (
    <div ref={ref}>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden p-4 outline-none focus:outline-none">
            <div
              className={cn(
                'relative mx-auto my-6 w-full',
                width === 'normal' ? 'w-full' : width,
              )}>
              {/*content*/}
              <div
                className={cn(
                  'shadow-lg relative flex flex-col rounded-lg border-0 bg-gray-15 outline-none focus:outline-none',
                )}
                ref={node}>
                {/*header*/}
                <div className="flex h-14 flex-row items-start justify-between rounded-t p-5 pb-0">
                  {!isNil(title) && (
                    <h3 className="text-3xl m-auto mx-auto flex-row text-center text-subtitle font-bold">
                      {title}
                    </h3>
                  )}
                  {!isNil(subtitle) && (
                    <h4 className="text-3xl font-semibold flex-row text-paragraph">
                      {subtitle}
                    </h4>
                  )}

                  <button
                    className="absolute bg-transparent text-3xl font-semibold right-6 border-0 leading-none text-black opacity-50 outline-none focus:outline-none"
                    onClick={onCancel}>
                    <span className="bg-transparent block text-subtitle text-black opacity-50 outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className={cn('relative flex-auto p-6', className)}>
                  {children}
                </div>
                {/*footer*/}
                <div className="flex p-6">
                  <div className="inline-block">
                    {showCancel && (
                      <Button
                        className="max-w mb-2 px-8 text-white"
                        variant="solid"
                        color="#C4C4C4"
                        onClick={onCancel}
                        isDisabled={isLoading}
                        size="sm">
                        {cancelText}
                      </Button>
                    )}
                    {persist && (
                      <Checkbox
                        onActive={onActive}
                        defaultValue={checkboxActive}
                        label={t(translations.INPUTS.DONT_SHOW_AGAIN)}
                      />
                    )}
                  </div>
                  <div className="ml-auto inline-block">
                    {showButtons && (
                      <Button
                        form={formId}
                        color={color}
                        className="max-w mb-2 px-8"
                        type="submit"
                        variant="primary"
                        isLoading={isLoading}
                        isDisabled={isDisabled || isLoading}
                        size="sm"
                        {...(isNil(formId)
                          ? { onClick: () => handleSuccess() }
                          : {})}>
                        {t(translations.COMMON.CONTINUE)}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-basic-black opacity-25"></div>
        </>
      ) : null}
    </div>
  );
});

Modal.displayName = 'Modal';
