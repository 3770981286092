import React, { useState, useEffect } from 'react';
import { css } from './helpers';
import { IPropsOf } from 'types';
import cn from 'classnames';

export interface CheckboxFieldProps extends IPropsOf<'div'> {
  /**
   * Input name.
   */
  name: string;
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Input label, can be a raw text or an i18n key.
   */
  label: string;
  /**
   * This prop will active once it changes, returns boolean value
   */
  onChange: (value: boolean) => void;
  /**
   * Input default value.
   */
  defaultValue: boolean;
  /**
   * Is disabled
   */
  disabled?: boolean;
}

export const CheckboxField = React.forwardRef<any, CheckboxFieldProps>(
  ({ className, label, name, onChange, defaultValue, disabled = false }, forwardedRef) => {
    const [checked, setChecked] = useState(defaultValue);

    useEffect(() => {
      if (onChange) {
        onChange(checked);
      }
    }, [checked]);

    return (
      <div className={cn(className, "w-full cursor-pointer")}>
        <label className="inline-flex cursor-pointer items-center">
          <input
            className={css.checkbox}
            ref={forwardedRef}
            type="checkbox"
            name={name}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
            disabled={disabled}
          />
          <span className={css.label}>{label}</span>
        </label>
      </div>
    );
  },
);

CheckboxField.displayName = 'CheckboxField';
