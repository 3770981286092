import loadable from '@loadable/component';
import Layout from 'layouts/Admin';

export const USER_ADMIN_ROUTE = '/users/:id/assets';
export const CONFIRMATION_ROUTE = '/confirmation';
export const EDIT_ORGANIZATION_ROUTE = '/edit-organization/:id';
export const CREATE_ORGANIZATION_ROUTE = '/create-organization';
export const FOLDERS_ROUTE = '/folders';
export const FOLDERS_ID_ROUTE = '/folders/:id';
export const HOME_ROUTE = '/';
export const INBOX_ROUTE = '/inbox';
export const INTEGRATIONS_ROUTE = '/integrations';
export const NEW_DOCUMENT_ROUTE = '/document';
export const DOCUMENT_ROUTE = '/document/:id';
export const INVITATION_ROUTE = '/sign_invitation';
export const ORGANIZATIONS_ROUTE = '/organizations';
export const PROFILE_ROUTE = '/profile';
export const MARKETING = '/marketing';
export const MARKETING_REWARDS = '/marketing/rewards';
export const MARKETING_REWARDS_UPDATE = '/marketing/rewards/:uuid';
export const REWARDS_ROUTE = '/rewards';
export const REWARD_DETAILS_ROUTE = '/rewards/:uuid';
export const FORMS_ROUTE = '/forms';
export const FORMS_ROUTE_NEW = '/forms/new';
export const FORMS_ROUTE_EDIT = '/forms/edit/:id';
export const FORMS_ROUTE_USE = ['/forms/use/:id', '/forms/use'];
export const FORMS_ROUTE_SIGN = '/forms/sign/:id';
export const FORMS_REDIRECT = '/teams/:teamId/forms/:formId';
export const CONTACTS_ROUTE = '/contacts';

const UserAdminPage = loadable(() => {
  return import('@pages/UserPages/AdminPage');
});
const EditOrganizationPage = loadable(() => {
  return import('@pages/OrganizationsPage/EditOrganizationPage');
});
const CreateOrganizationPage = loadable(() => {
  return import('@pages/OrganizationsPage/CreateOrganizationPage');
});
const FoldersPage = loadable(() => {
  return import('@pages/FoldersPage/FoldersListPage');
});
const FolderPage = loadable(() => {
  return import('@pages/FoldersPage/FolderPage');
});
const HomePage = loadable(() => {
  return import('@pages/HomePage');
});
const IntegrationsPage = loadable(() => {
  return import('@pages/IntegrationsPage');
});
const CreateDocument = loadable(() => {
  return import('@pages/DocumentPage/CreateDocument');
});
const SignDocument = loadable(() => {
  return import('@pages/DocumentPage/SignDocument');
});
const OrganizationsPage = loadable(() => {
  return import('@pages/OrganizationsPage/OrganizationsPage');
});
const ProfilePage = loadable(() => {
  return import('@pages/UserPages/ProfilePage');
});
const MarketingCreatePage = loadable(() => {
  return import('@pages/MarketingPages/CreatePage');
});
const MarketingRewardsPage = loadable(() => {
  return import('@pages/MarketingPages/RewardsPages/ListingPage');
});
const MarketingRewardsUpdatePage = loadable(() => {
  return import('@pages/MarketingPages/RewardsPages/UpdatePage');
});
const RewardsPage = loadable(() => {
  return import('@pages/RewardsPages/ListingPage');
});
const RewardDetailsPage = loadable(() => {
  return import('@pages/RewardsPages/DetailsPage');
});
const FormTemplatesPage = loadable(() => {
  return import('@pages/FormTemplatesPage/ListingPage');
});
const NewFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/CreateFormPage');
});
const EditFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/CreateFormPage');
});
const UseFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/UseFormPage');
});
const SignFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/SignFormPage');
});
const RedirectFormPage = loadable(() => {
  return import('@pages/FormTemplatesPage/FormRedirectPage');
});
const ContactsPage = loadable(() => {
  return import('@pages/ContactsPage');
});

interface RouteOptions {
  key: string;
  path: string | Array<string>;
  isPrivate: boolean;
  component: any;
  layout?: any;
  policy?: string;
}

export const ROUTES: Array<RouteOptions> = [
  {
    key: 'user/admin',
    path: USER_ADMIN_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: UserAdminPage,
  },
  {
    key: 'inbox',
    path: INBOX_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: HomePage,
  },
  {
    key: 'marketing',
    path: MARKETING,
    isPrivate: true,
    layout: Layout,
    component: MarketingCreatePage,
  },
  {
    key: 'marketing-rewards',
    path: MARKETING_REWARDS,
    isPrivate: true,
    layout: Layout,
    component: MarketingRewardsPage,
  },
  {
    key: 'marketing-rewards-update',
    path: MARKETING_REWARDS_UPDATE,
    isPrivate: true,
    layout: Layout,
    component: MarketingRewardsUpdatePage,
  },
  {
    key: 'rewards',
    path: REWARDS_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: RewardsPage,
  },
  {
    key: 'reward-details',
    path: REWARD_DETAILS_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: RewardDetailsPage,
  },
  {
    key: 'edit-organization',
    path: EDIT_ORGANIZATION_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: EditOrganizationPage,
  },
  {
    key: 'create-organization',
    path: CREATE_ORGANIZATION_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: CreateOrganizationPage,
  },
  {
    key: 'folders',
    path: FOLDERS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: FoldersPage,
  },
  {
    key: 'folder',
    path: FOLDERS_ID_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: FolderPage,
  },
  {
    key: 'home',
    path: HOME_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: HomePage,
  },
  {
    key: 'integrations',
    path: INTEGRATIONS_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: IntegrationsPage,
  },
  {
    key: 'new-document',
    path: NEW_DOCUMENT_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: CreateDocument,
  },
  {
    key: 'document',
    path: DOCUMENT_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: SignDocument,
  },
  {
    key: 'invitation-sign',
    path: INVITATION_ROUTE,
    isPrivate: false,
    layout: Layout,
    component: SignDocument,
  },
  {
    key: 'organizations',
    path: ORGANIZATIONS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: OrganizationsPage,
  },
  {
    key: 'profle',
    path: PROFILE_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: ProfilePage,
  },
  {
    key: 'forms',
    path: FORMS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: FormTemplatesPage,
  },
  {
    key: 'forms-new',
    path: FORMS_ROUTE_NEW,
    isPrivate: true,
    layout: Layout,
    component: NewFormPage,
  },
  {
    key: 'forms-edit',
    path: FORMS_ROUTE_EDIT,
    isPrivate: true,
    layout: Layout,
    component: EditFormPage,
  },
  {
    key: 'forms-use',
    path: FORMS_ROUTE_USE,
    isPrivate: true,
    layout: Layout,
    component: UseFormPage,
  },
  {
    key: 'forms-sign',
    path: FORMS_ROUTE_SIGN,
    isPrivate: false,
    layout: Layout,
    component: SignFormPage,
  },
  {
    key: 'forms-redirect',
    path: FORMS_REDIRECT,
    isPrivate: false,
    layout: Layout,
    component: RedirectFormPage,
  },
  {
    key: 'contacts',
    path: CONTACTS_ROUTE,
    isPrivate: true,
    layout: Layout,
    component: ContactsPage,
  },
];
