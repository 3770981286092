import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import setupLogRocketReact from 'logrocket-react';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/globals.css';
import './styles/viewer.css';
import 'focus-visible/dist/focus-visible';
import 'react-datepicker/dist/react-datepicker.css';

const SENTRY_KEY = process.env.REACT_APP_SENTRY;
const LOG_ROCKET = process.env.REACT_APP_LOGROCKET || '';
const env = process.env.NODE_ENV;
const isReviewApp = process.env.REACT_APP_IS_REVIEW;
const isProduction = env === 'production' && !isReviewApp;
const MIXPANEL = process.env.REACT_APP_MIXPANEL || '';

console.info(
  'ENV',
  isProduction ? 'production' : isReviewApp ? 'review-app' : 'development',
);

if (isProduction) {
  /**
   * Sentry
   */
  Sentry.init({
    dsn: SENTRY_KEY,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.6,
  });

  LogRocket.init(LOG_ROCKET);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
  setupLogRocketReact(LogRocket);

  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
}

mixpanel.init(MIXPANEL, { debug: !isProduction });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
