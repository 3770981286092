import { WLContext } from '@contexts';
import { cx, css } from '@emotion/css';
import React, { useContext, useState } from 'react';

export interface CheckboxProps {
  className?: string;
  label: string;
  onActive?: () => void;
  onChange?: any;
  defaultValue?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className = '',
  onActive,
  onChange,
  defaultValue = true,
  label = '',
}) => {
  const [active, setActive] = useState<boolean>(defaultValue);
  const { color } = useContext(WLContext);
  const handleCheckbox = () => {
    if (onActive && !active) onActive();
    if (onChange) onChange(!active);
    setActive((prev) => !prev);
  };
  return (
    <div className={className}>
      <label className="inline-flex items-center cursor-pointer">
        <input
          onClick={handleCheckbox}
          id="customCheckLogin"
          type="checkbox"
          checked={active}
          className={cx("form-checkbox ml-1 w-5 h-5 text-primary-300 border rounded transition-all duration-150 ease-linear", css`accent-color: ${color}`)}
        />
        <span className="text-sm font-semibold text-blueGray-600 ml-2">
          {label}
        </span>
      </label>
    </div>
  );
};
