import React, { ReactElement } from 'react';
import cn from 'classnames';
import { createPopper } from '@popperjs/core';
export interface PopOverProps {
  children: JSX.Element;
  className?: string;
  content: ReactElement;
}

/**
 * Buttons are used to initialize an action. Button labels express what action will occur when the user interacts with it.
 */
export const PopOver: React.FC<PopOverProps> = ({
  children,
  className,
  content,
}) => {
  const [popoverShow, setPopoverShow] = React.useState(false);
  const btnRef: any = React.createRef();
  const popoverRef: any = React.createRef();
  const openPopover = () => {
    createPopper(btnRef.current, popoverRef.current, {
      placement: 'bottom',
    });
    setPopoverShow(true);
  };
  const closePopover = () => {
    setPopoverShow(false);
  };

  const popOverClass = cn('m-auto mt-2', {
    'focus:outline-none ': true,
  });

  return (
    <div className={className}>
      <div className="flex">
        <button
          className={popOverClass}
          type={'button'}
          onClick={() => {
            popoverShow ? closePopover() : openPopover();
          }}
          onMouseEnter={() => openPopover()}
          onMouseLeave={() => closePopover()}
          ref={btnRef}
        >
          {children}
        </button>
      </div>

      <div
        className={
          (popoverShow ? '' : 'hidden ') +
          ' top-4  mr-3 block z-50 font-normal leading-normal text-sm max-w-xs mt-2 text-left no-underline break-words rounded-lg'
        }
        ref={popoverRef}
        style={{ marginTop: 5 }}
      >
        <div>
          <div className="mt-4 p-3 text-paragraph bg-gray-100 border-0 rounded-md">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};
