/** @jsx jsx */
import React from 'react';
import {
  ButtonSize,
  ButtonType,
  ButtonVariant,
  getCustomCSS,
  getPropsCSS,
} from '@helpers';
import { Spinner, Stack } from '@design-system';
import { Base } from './components/Base';
import { Content } from './components/Content';
import cn from 'classnames';
import fp from 'lodash/fp';

import { jsx } from '@emotion/react';
import { IPropsOf } from 'types';

export interface IButtonProps extends IPropsOf<'button'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Custom White Label color.
   */
  color: string;
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean;
  /**
   * Icon that will be rendered on the left side of the button's children.
   */
  leftIcon?: React.ReactElement;
  /**
   * On click event handler.
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * Icon that will be rendered on the right side of the button's children.
   */
  rightIcon?: React.ReactElement;
  /**
   * Button size must be one of the following keys:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  size?: keyof typeof ButtonSize;
  /**
   * Button type must be one of the following keys:
   * "button" | "submit" | "reset".
   */
  type?: keyof typeof ButtonType;
  /**
   * Button variant must be one of the following keys:
   * "primary" | "solid" | "outline" | "ghost" | "link".
   */
  variant?: keyof typeof ButtonVariant;
}

export const Button: React.FC<IButtonProps> = (props): JSX.Element => {
  const {
    children,
    className,
    color,
    isDisabled = false,
    isFullWidth = false,
    isLoading = false,
    leftIcon,
    onClick,
    rightIcon,
    size = 'md',
    type = 'button',
    variant = 'primary',
  } = props;

  return (
    <Base
      className={cn(
        ButtonSize[size],
        getPropsCSS(isDisabled, isFullWidth),
        className,
      )}
      css={getCustomCSS(color, variant)}
      disabled={isDisabled}
      onClick={onClick}
      type={type}>
      {isLoading ? <Spinner className="absolute" size={size} /> : null}

      <Stack className="items-center" isInline>
        {!fp.isNil(leftIcon) ? leftIcon : null}

        <Content
          hasLI={!fp.isNil(leftIcon)}
          hasRI={!fp.isNil(rightIcon)}
          isLoading={isLoading}>
          {children}
        </Content>

        {!fp.isNil(rightIcon) ? rightIcon : null}
      </Stack>
    </Base>
  );
};
