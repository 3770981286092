import React, { useContext, useState, useEffect } from 'react';
import { useRouter } from 'hooks/useRouter';
import { Sidebar, NavBar } from '@design-system';
import {
  tokenName,
  tokenV3Name,
  expirationName,
  userName,
  cincelSession,
} from 'config/constants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useQuery } from 'react-query';
import { useClient } from 'hooks/useClient';
import { useSession } from 'store/session';
import { useToast } from '@chakra-ui/react';
import { convertSize } from 'utils/convertSize';
import { use100vh } from 'react-div-100vh';
import { WLContext } from '@contexts';
import { useSentry } from 'hooks/useSentry';
import { useTeams } from 'hooks/useTeams';

const fetchAccountLimits = async (client, organizationId) => {
  return client.accountLimits(organizationId);
};

const fetchAccountSubscription = async (client) => {
  const subscription = await client.accountSubscription();

  if (!subscription.ok)
    return {
      ok: false,
      errors: {
        error: 'No fue posible obtener la suscripción',
      },
    };

  const suscriptions = subscription.payload;
  const procesedSuscriptions: any = [];
  let currentActivePlan: any = '';
  for (const i in suscriptions) {
    const processedPlan = {
      name: suscriptions[i].subscription_plan.plan_product.name,
      status: suscriptions[i].status,
      type: suscriptions[i].subscription_plan.type,
      cancelled: suscriptions[i].cancel_at_period_end ? true : false,
      start_date: suscriptions[i].current_period_start_date,
      end_date: suscriptions[i].current_period_end_date,
    };
    if (
      processedPlan.status == 'active' &&
      processedPlan.name != 'extra_user' &&
      processedPlan.name != 'storage'
    ) {
      currentActivePlan = processedPlan;
    }
    procesedSuscriptions.push(processedPlan);
  }

  return {
    procesedSuscriptions,
    currentActivePlan,
  };
};

export default function AdminLayout({ className = '', children }) {
  const { color, isRewardsAvailable, lightLogo } = useContext(WLContext);
  const { captureException } = useSentry();

  const {
    user,
    currentOrganization,
    setCurrentPlan,
    setAccountLimits,
    clean,
    setShowBasicCertificates,
    setTeams
  } = useSession();
  const client = useClient({
    url: process.env.REACT_APP_API,
    token: localStorage.getItem(tokenName) as string,
  });

  const toast = useToast();
  const height = use100vh();

  const { userTeams } = useTeams({ id: user?.id, enabled: user !== null })

  const {
    data: accountSubscription,
    isError,
    error,
  } = useQuery<any>(
    ['accountSubscription'],
    () => fetchAccountSubscription(client),
    { enabled: user !== null, retry: false },
  );
  const {
    data: accountLimits,
    isError: isLimitsError,
    error: limitsError,
    refetch: refetchAccountLimits,
  } = useQuery(
    ['limits'],
    () => fetchAccountLimits(client, (currentOrganization as any).id),
    {
      enabled: user !== null,
      retry: false,
    },
  );

  const removeSession = () => {
    localStorage.removeItem(tokenName);
    localStorage.removeItem(tokenV3Name);
    localStorage.removeItem(expirationName);
    localStorage.removeItem(userName);
    localStorage.removeItem(cincelSession);
    clean();
    router.history.push('/login');
  };

  useEffect(() => {
    if (userTeams) {
      setTeams(userTeams.data)
    }
  }, [userTeams]);

  useEffect(() => {
    if (isLimitsError && limitsError) {
      removeSession();
      toast({
        position: 'top-right',
        isClosable: true,
        title:
          'Tu sesión ha expirado. Por favor, identifícate de nuevo para continuar.',
        status: 'error',
      });
    }

    if (accountLimits && accountLimits?.payload) {

      const { payload } = accountLimits;

      const count = payload.count_documents_vobo_nom151;
      const max = payload.max_documents_vobo_nom151;

      if (max > count) {
        setShowBasicCertificates(true);
      } else {
        setShowBasicCertificates(false);
      }

      if (!accountLimits.ok && accountLimits.errors) {
        const error = Object.keys(accountLimits.errors)
          .map((key) => accountLimits.errors[key][0])
          .join(',');
        toast({
          position: 'top-right',
          isClosable: true,
          title: error,
          status: 'error',
        });
        removeSession();
      }

      if (accountLimits.ok && accountLimits.payload) {
        setAccountLimits(accountLimits.payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLimits, isLimitsError, limitsError]);

  useEffect(() => {
    if (isError && error) {
      removeSession();
      toast({
        position: 'top-right',
        isClosable: true,
        title:
          'Tu sesión ha expirado. Por favor, identifícate de nuevo para continuar.',
        status: 'error',
      });
    }

    if (accountSubscription && accountLimits) {
      if (!accountSubscription.ok && accountSubscription.errors) {
        const error = Object.keys(accountSubscription.errors)
          .map((key) => accountSubscription.errors[key][0])
          .join(',');
        toast({
          position: 'top-right',
          isClosable: true,
          title: error,
          status: 'error',
        });
        removeSession();
      }

      if (
        accountSubscription.currentActivePlan &&
        accountLimits.ok &&
        accountLimits.payload
      ) {
        const totalSize = convertSize(accountLimits.payload.max_storage);
        if (totalSize === '100 GB') {
          setCurrentPlan({
            ...accountSubscription.currentActivePlan,
            name: 'start',
          });
        } else {
          setCurrentPlan(accountSubscription.currentActivePlan);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSubscription, isError, error, accountLimits]);

  useEffect(() => {
    if (user !== null) refetchAccountLimits();
  }, [currentOrganization, refetchAccountLimits, user]);

  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const handleProfile = () => {
    router.history.push('/profile');
  };

  const handleLogout = () => {
    removeSession();
  };

  return (
    <div className="flex" style={{ minHeight: `${height}px` }}>
      <Sidebar
        logo={lightLogo}
        primaryColor={color}
        handleLogout={handleLogout}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isRewardsAvailable={isRewardsAvailable}
      />

      <div className="flex flex-1 flex-col justify-between overflow-hidden">
        <NavBar
          logo={lightLogo}
          handleProfile={handleProfile}
          handleLogout={handleLogout}
          setIsOpen={setIsOpen}
          primaryColor={color}
        />
        {/* Header */}
        <div className="flex-1 overflow-y-auto overflow-x-hidden pt-20 lg:pt-0">
          <div className="mx-auto mb-20 px-0 pt-0 md:px-6 md:pt-0 lg:mt-20 lg:py-8 lg:px-16">
            <DndProvider backend={HTML5Backend}>
              <div className={className}>
                {children}
              </div>
            </DndProvider>
          </div>
        </div>
        <footer className="flex justify-center lg:px-16 lg:justify-end my-5">
          <p className="text-[#A5A5A5] text-md">
            <span>Powered by </span>
            <strong>CINCEL</strong>
          </p>
        </footer>
      </div>
    </div>
  );
}
