/** @jsx jsx */
import React from 'react';
import {
  getCustomCSS,
  getPropsCSS,
  ButtonType,
  ButtonVariant,
  IconButtonSize,
} from '@helpers';
import { Base } from './components/Base';
import cn from 'classnames';
import fp from 'lodash/fp';

import { jsx } from '@emotion/react';

export interface IIconButtonProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Custom White Label color.
   */
  color: string;
  /**
   * Icon that will be rendered as the button's children.
   */
  icon: React.ReactElement;
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the button will have a background color like hover.
   */
  isSelected?: boolean;
  /**
   * On click event handler.
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * Button size must be one of the following keys:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  size?: keyof typeof IconButtonSize;
  /**
   * Button type must be one of the following keys:
   * "button" | "submit" | "reset".
   */
  type?: keyof typeof ButtonType;
  /**
   * Button variant must be one of the following keys:
   * "primary" | "solid" | "outline" | "ghost" | "link".
   */
  variant?: keyof typeof ButtonVariant;
}

export const IconButton: React.FC<IIconButtonProps> = (
  props,
): JSX.Element | null => {
  const {
    className,
    color,
    icon,
    isDisabled = false,
    onClick,
    size = 'md',
    type = 'button',
    variant = 'primary',
    isSelected = false,
  } = props;

  if (fp.isNil(icon)) return null;

  return (
    <Base
      className={cn(IconButtonSize[size], getPropsCSS(isDisabled), className)}
      css={getCustomCSS(color, variant, isSelected)}
      disabled={isDisabled}
      onClick={onClick}
      type={type}>
      {icon}
    </Base>
  );
};
